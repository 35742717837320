import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Container,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Heading,
  Input,
  Stack,
} from '@chakra-ui/react';
import InputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../context/AuthContext';

export default function VerificationPage() {
  const navigate = useNavigate();
  const auth = useAuthContext();

  const VerificationSchema = Yup.object().shape({
    verificationCode: Yup.string()
      .trim()
      .min(6, 'Must be a valid verification code')
      .required('Please enter your verification code'),
  });

  const formik = useFormik({
    initialValues: {
      verificationCode: '',
    },
    validationSchema: VerificationSchema,
    onSubmit: async (values) => {
      const success = await auth.answerChallenge(values.verificationCode);

      if (success) {
        navigate('/dashboard');
      } else {
        formik.errors.verificationCode = 'Invalid verification code';
        console.error('User not approved :(');
      }
    },
  });

  return (
    <Container {...styles.container}>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={8}>
          <Heading>schext</Heading>
          <FormControl
            isInvalid={
              !!formik.errors.verificationCode &&
              formik.touched.verificationCode
            }
          >
            <Input
              {...styles.input}
              as={InputMask}
              mask={'999999'}
              maskPlaceholder={null}
              id="verificationCode"
              name="verificationCode"
              value={formik.values.verificationCode}
              onChange={formik.handleChange}
              textAlign="center"
            />
            <FormHelperText {...styles.helperText}>
              Please enter your verification code
            </FormHelperText>
            <FormErrorMessage {...styles.errorMessage}>
              {formik.errors.verificationCode}
            </FormErrorMessage>
          </FormControl>
          <Button
            {...styles.btn}
            type="submit"
            isLoading={formik.isSubmitting}
            loadingText="Verifying Code"
          >
            Next
          </Button>
        </Stack>
      </form>
    </Container>
  );
}

const styles = {
  container: {
    color: 'white',
    size: 'sm',
    align: 'center',
    minHeight: 'calc(100vh - 300px)',
    py: 20,
    sx: {
      a: {
        fontWeight: 'bold',
      },
    },
  },
  input: {
    borderColor: 'rgb(255, 255, 255, 0.2)',
    focusBorderColor: 'white',
    variant: 'flushed',
    fontSize: 32,
    letterSpacing: 5,
    sx: {
      '::placeholder': {
        color: 'white',
      },
    },
  },
  btn: {
    alignSelf: 'center',
  },
  errorMessage: {
    backgroundColor: 'rgb(255, 255, 255, 0.8)',
    fontWeight: 'bold',
  },
  helperText: {
    color: '#fff',
    fontSize: 20,
  },
};

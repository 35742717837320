const API = process.env.REACT_APP_API_URL;

export const appointmentApi = {
  async getAppointments(organizationId: number) {
    const response = await fetch(`${API}/appointments/${organizationId}`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    });
    return await response.json();
  },
  async cancelAppointment(appointmentId: number) {
    const response = await fetch(`${API}/appointments/${appointmentId}`, {
      method: 'DELETE',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    });
    return await response.json();
  },
};

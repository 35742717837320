import { CheckUserVerification, CreateUserRequest } from './models/user';
import { User } from './models/user';
import { Auth } from 'aws-amplify';

const API = process.env.REACT_APP_API_URL;

export const cleanPhoneNumber = (phone: string) =>
  '+1' + phone.replace(/[- )(]/g, '');

export const userApi = {
  async createUser(data: CreateUserRequest) {
    const response = await fetch(`${API}/users/`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(data),
    });
    return response.json();
  },

  async getUser(id: number) {
    const response = await fetch(`${API}/users/${id}`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    });
    return response.json();
  },

  async sendVerification(phone: string) {
    return Auth.signIn(cleanPhoneNumber(phone));
  },

  async checkVerification(data: CheckUserVerification) {
    const response = await fetch(`${API}/users/login/verification`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(data),
    });
    return response.json();
  },

  async getUserByPhone(phone: string): Promise<UserByPhoneResponse> {
    const response = await fetch(`${API}/users/${phone}`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    });
    return await response.json();
  },

  async checkUserExistPhone(phone: string) {
    const response = await fetch(`${API}/users/user-exist`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(phone),
    });
    return response.json();
  },

  async checkUserExistEmail(email: string) {
    const response = await fetch(`${API}/users/user-email-exist`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(email),
    });
    return response.json();
  },
};

export type UserByPhoneResponse = [{ success: boolean; user: User }, number];

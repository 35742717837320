import {
  Box,
  Button,
  Grid,
  Heading,
  Stack,
  StackDirection,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { scheduleApi } from '../../api/schedules';
import AddScheduleModal from './AddScheduleModal';
import EditScheduleModal from './EditScheduleModal';

export default function SchedulePage() {
  const { business } = useParams();
  const id = business?.split('&')[0];
  const [isOpen, setIsOpen] = useState(false);
  const [schedules, setSchedules] = useState<any>();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [editModalData, setEditModalData] = useState<any>();

  useEffect(() => {
    (async () => {
      const result = await scheduleApi.getSchedules(parseInt(id!));
      setSchedules(result.schedules);
    })();
  }, [isOpen, isEditOpen]);

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const onOpenEdit = (schedule) => {
    setEditModalData(schedule);
    setIsEditOpen(true);
  };

  const onCloseEdit = () => {
    setIsEditOpen(false);
  };

  return (
    <>
      <Stack style={{ alignItems: 'end' }}>
        <Button
          variant={'glass'}
          {...styles.addButton}
          textAlign={'right'}
          onClick={onOpen}
        >
          + Add day to schedule
        </Button>
        <AddScheduleModal
          schedules={schedules}
          isOpen={isOpen}
          onClose={onClose}
        />
      </Stack>
      <Grid {...styles.scheduleRow} gap={10}>
        {schedules?.map((schedule) => {
          return (
            <Box
              {...styles.scheduleBox}
              key={schedule.id}
              as="button"
              onClick={() => onOpenEdit(schedule)}
            >
              <Heading>{getDay(DayOfWeek, schedule.dayOfWeek)}</Heading>
              <Text {...styles.time}>{schedule.startTime}</Text>
              <Text>-</Text>
              <Text {...styles.time}>{schedule.endTime}</Text>
            </Box>
          );
        })}
        <EditScheduleModal
          day={editModalData}
          isOpen={isEditOpen}
          onClose={onCloseEdit}
        />
      </Grid>
    </>
  );
}

const styles = {
  header: {
    fontSize: 32,
    marginBottom: -5,
    sx: {
      span: {
        fontWeight: 'normal',
      },
    },
  },
  addButton: {
    width: 200,
    marginBottom: 10,
  },
  scheduleRow: {
    justifyContent: 'center',
    templateColumns: [
      'repeat(1, 1fr)',
      'repeat(2, 1fr)',
      'repeat(2, 1fr)',
      'repeat(3, 1fr)',
      'repeat(5, 1fr)',
    ],
    alignItems: 'center',
    placeItems: 'center',
  },
  scheduleBox: {
    backgroundColor: 'rgb(255, 255, 255, 0.2)',
    width: 250,
    height: 175,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
  },
  stack: {
    color: 'white',
    spacing: 8,
    direction: ['column', 'row'] as StackDirection,
    align: ['flex-start', 'center'],
    justify: 'space-between',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 5,
    paddingBottom: 5,
  },
  time: {
    fontSize: 25,
  },
};

enum DayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

function getDay<T>(type: T, day: number): T[keyof T] {
  const casted = day as keyof T;
  return type[casted];
}

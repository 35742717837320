import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export default function appointmentExistModal({ isOpen, onClose }: Props) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader />
        <ModalCloseButton color={'black'} />
        <ModalBody>
          <Text {...styles.modalText} textAlign="center">
            Cannot delete a client with upcoming/active appointment(s).
            <br />
            Please cancel this client's appointment(s), then try removing this
            client again.
            <br />
            Thank you!
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

const styles = {
  modalText: {
    color: 'black',
    fontSize: 20,
  },
};

import { AddScheduleRequest, UpdateScheduleRequest } from './models/schedule';

const API = process.env.REACT_APP_API_URL;

export const scheduleApi = {
  async getSchedules(organizationId: number) {
    const response = await fetch(`${API}/schedules/${organizationId}`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    });
    return await response.json();
  },

  async addSchedule(data: AddScheduleRequest) {
    const response = await fetch(`${API}/schedules/`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(data),
    });
    return response.json();
  },

  async updateSchedule(data: UpdateScheduleRequest) {
    const response = await fetch(`${API}/schedules/`, {
      method: 'PUT',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(data),
    });
    return response.json();
  },

  async deleteSchedule(dayId: number) {
    const response = await fetch(`${API}/schedules/${dayId}`, {
      method: 'DELETE',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    });
    return response.json();
  },
};

import {
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { clientApi, cleanPhoneNumber } from '../../../api/clients';
import regex from '../../../utils/regex';
import InputMask from 'react-input-mask';
import { CreateClientRequest } from '../../../api/models/client';

interface Props {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export default function AddClientModal({ isOpen, onOpen, onClose }: Props) {
  const { business } = useParams();
  const id = business?.split('&')[0];

  const AddClientSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .min(3, 'Name must be more than 3 characters')
      .required('Please enter a name for your client'),
    phone: Yup.string()
      .trim()
      .matches(regex.phone, 'Must be a valid phone number')
      .min(14, 'Must be a valid phone number')
      .required('Please enter a phone number'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
      organizationId: parseInt(id!),
    },
    validationSchema: AddClientSchema,
    onSubmit: async (values) => {
      const request: CreateClientRequest = { ...values };
      request.phone = cleanPhoneNumber(values.phone);

      const clientPhoneExist = await clientApi.checkClientExist(request.phone);

      if (clientPhoneExist.response === 404) {
        const response = await clientApi.createClient(request);
        if (response.response === 201) {
          formik.resetForm();
          onClose();
        } else {
          console.log('Could not create client');
        }
      } else if (clientPhoneExist.response === 409) {
        formik.errors.phone = 'A client with this phone number already exists';
      }
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalOverlay />
      <form onSubmit={formik.handleSubmit}>
        <ModalContent>
          <ModalHeader {...styles.header}>Add Client</ModalHeader>
          <ModalCloseButton color={'black'} />
          <ModalBody>
            <FormControl
              isInvalid={!!formik.errors.name && formik.touched.name}
            >
              <FormLabel htmlFor="name" {...styles.label}>
                Name
              </FormLabel>
              <Input
                id="name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                color="black"
              />
              <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={!!formik.errors.phone && formik.touched.phone}
            >
              <FormLabel htmlFor="phone" {...styles.label}>
                Phone Number
              </FormLabel>
              <Input
                as={InputMask}
                mask={'(999) 999 9999'}
                maskPlaceholder=""
                id="phone"
                type="tel"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                color="black"
              />
              <FormErrorMessage>{formik.errors.phone}</FormErrorMessage>
            </FormControl>
            <ButtonGroup style={{ marginTop: 20 }}>
              <Button {...styles.cancelButton} onClick={onClose}>
                Cancel
              </Button>
              <Button {...styles.submitButton} type="submit">
                Add Client
              </Button>
            </ButtonGroup>
          </ModalBody>
        </ModalContent>
      </form>
    </Modal>
  );
}

const styles = {
  header: {
    color: 'black',
    fontWeight: 'bold',
  },
  label: {
    color: 'black',
  },
  errorMessage: {
    color: 'pink',
    p: 1,
    justifyContent: 'center',
    fontWeight: 'bold',
  },
  submitButton: {
    backgroundColor: 'rgba(0, 0, 0, .2)',
    color: 'white',
    width: 115,
  },
  cancelButton: {
    border: '1px solid',
    borderColor: 'grey',
    backgroundColor: 'transparent',
    color: 'black',
    width: 100,
  },
};

import {
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

interface Props {
  phone: string;
  isOpen: boolean;
  onClose: () => void;
}

export default function ContactModal({ phone, isOpen, onClose }: Props) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color={'black'}>Contact Client</ModalHeader>
        <ModalCloseButton color={'black'} />
        <ModalBody>
          <Heading color={'black'}>
            {phone?.replace(/^\+[1](\d{3})(\d{3})(\d{4})/, '($1) $2 $3')}
          </Heading>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

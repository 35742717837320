import type { ComponentStyleConfig } from '@chakra-ui/react';

// All Button overrides
const ButtonStyle: ComponentStyleConfig = {
  baseStyle: {
    _hover: {
      color: 'black',
      bg: 'white',
    },
  },
  variants: {
    solid: { bg: 'white', color: 'black', fontWeight: 'bold' }, // TODO: Convert rgb(255, 255, 255, 0.2) to usable color variables
    glass: {
      _hover: {
        color: 'black',
        bg: 'rgb(255, 255, 255, 0.1)',
      },
      bg: 'rgb(255, 255, 255, 0.2)',
      color: 'white',
      fontWeight: 'bold',
    },
  },
  sizes: {
    lg: {
      w: 200,
      px: 6,
    },
  },
  defaultProps: {
    rounded: 'lg',
    size: 'lg',
  },
};

export default ButtonStyle;

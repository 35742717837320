import { useFormik } from 'formik';
import * as Yup from 'yup';
import regex from '../../utils/regex';
import {
  Button,
  Container,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Heading,
  Stack,
} from '@chakra-ui/react';
import InputMask from 'react-input-mask';
import { cleanPhoneNumber, userApi } from '../../api/users';
import { useNavigate } from 'react-router-dom';
import Input from '../../components/Input';
import { useAuthContext } from '../../context/AuthContext';

export default function SignInPage() {
  const navigate = useNavigate();
  const authContext = useAuthContext();

  const SignInSchema = Yup.object().shape({
    phone: Yup.string()
      .trim()
      .matches(regex.phone, 'Must be a valid phone number')
      .min(14, 'Must be a valid phone number')
      .required('Please enter a phone number'),
  });

  const formik = useFormik({
    initialValues: {
      phone: '',
    },
    validationSchema: SignInSchema,
    onSubmit: async (values) => {
      const phone = cleanPhoneNumber(values.phone);
      const userExist = await userApi.checkUserExistPhone(phone);
      if (userExist.response === 409) {
        await authContext.initiateChallenge(phone);
        navigate(`/signin/verification`);
      } else if (userExist.response === 404) {
        formik.errors.phone = 'No account with that phone number found';
      }
    },
  });

  return (
    <Container {...styles.container}>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={8}>
          <Heading>schext</Heading>
          <FormControl
            isInvalid={!!formik.errors.phone && formik.touched.phone}
          >
            <Input
              as={InputMask}
              mask={'(999) 999 9999'}
              maskPlaceholder=""
              id="phone"
              type="tel"
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              textAlign="center"
              fontSize={32}
              letterSpacing={5}
            />
            <FormHelperText {...styles.helperText}>
              Enter your phone number to login
            </FormHelperText>
            <FormErrorMessage {...styles.errorMessage}>
              {formik.errors.phone}
            </FormErrorMessage>
          </FormControl>
          <Button
            {...styles.btn}
            type="submit"
            isLoading={formik.isSubmitting}
            loadingText="Signing in"
          >
            Next
          </Button>
        </Stack>
      </form>
    </Container>
  );
}

const styles = {
  container: {
    size: 'sm',
    align: 'center',
    minHeight: 'calc(100vh - 300px)',
    py: 20,
    sx: {
      a: {
        fontWeight: 'bold',
      },
    },
  },
  btn: {
    alignSelf: 'center',
  },
  errorMessage: {
    color: 'pink',
    p: 1,
    justifyContent: 'center',
    fontWeight: 'bold',
  },
  helperText: {
    color: '#fff',
    fontSize: 20,
    mt: 5,
  },
};

import { CalendarIcon, ChatIcon, TimeIcon } from '@chakra-ui/icons';
import {
  Container,
  Stack,
  Heading,
  StackDirection,
  Text,
  Image,
  Button,
  SimpleGrid,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Feature from '../../components/Feature';

interface Props {
  loggedOut?: boolean;
}

export default function HomePage({ loggedOut }: Props) {
  const navigate = useNavigate();
  const [alert, setAlert] = useState(loggedOut);

  useEffect(() => {
    setTimeout(() => {
      setAlert(false);
    }, 5000);
  }, []);

  return (
    <>
      <Container {...styles.container}>
        {alert && (
          <Alert
            status="success"
            borderRadius={15}
            color="#3f3f3f"
            fontWeight="bold"
          >
            <AlertIcon />
            You have been succesfully logged out
          </Alert>
        )}
        <Stack {...styles.stack}>
          <Stack {...styles.textStack}>
            <Heading {...styles.header}>
              s<span>chext</span> - The new way for appointment scheduling
            </Heading>
            <Text>
              Schext is a powerful text schedule tool, that allows your clients
              to set appointments without you. After signing up a client on your
              business dashboard, they can use the number that text's them to
              schedule appointments with you based on your set times/days and
              based on open non booked times.{' '}
            </Text>
            <Button onClick={() => navigate('/signup')}>Get Started</Button>
          </Stack>
          <Image src="images/calendar.png" alt="Hero Phone Image" />
        </Stack>
      </Container>

      <Container {...styles.featuresContainer}>
        <SimpleGrid as={Container} {...styles.featuresGrid}>
          <Feature title="Set your working days" Icon={CalendarIcon} />
          <Feature
            title="Your clients text for an appointment"
            Icon={ChatIcon}
          />
          <Feature
            title="Appointment set without you having to do anything"
            Icon={TimeIcon}
          />
        </SimpleGrid>
      </Container>

      <Container {...styles.ctaContainer}>
        <Stack {...styles.stack} py={0}>
          <Image src="images/scheduled.png" alt="Hero Phone Image" />
          <Stack {...styles.textStack} align="center">
            <Heading>Start making your scheduling easier today!</Heading>
            <Button onClick={() => navigate('/signup')}>Sign up</Button>
          </Stack>
        </Stack>
      </Container>
    </>
  );
}

const styles = {
  stack: {
    color: 'white',
    spacing: 8,
    py: 20,
    direction: ['column', 'row'] as StackDirection,
    align: ['flex-start', 'center'],
    justify: 'center',
  },
  header: {
    sx: {
      span: {
        fontWeight: 'normal',
      },
    },
  },
  textStack: {
    spacing: [5, 10],
  },
  container: {
    size: 'lg',
  },
  featuresContainer: {
    p: 16,
    color: 'white',
    rounded: 'lg',
    backgroundColor: 'rgb(255, 255, 255, 0.2)',
  },
  featuresGrid: {
    columns: [1, 3],
    spacing: [20, 10],
    size: 'lg',
    textAlign: 'center' as const,
  },
  ctaContainer: {
    py: 20,
    my: 10,
    color: 'white',
  },
};

// All Global style overrides

const styles = {
  global: {
    html: {
      height: '100%',
    },
    body: {
      background: 'linear-gradient(#6A52EE, #7AE0D9) fixed',
      color: 'white',
    },
  },
};

export default styles;

import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import Verification from './pages/SignIn/Verification';
import Dashboard from './pages/Dashboard';
import { useAuthContext } from './context/AuthContext';
import ReactGA from 'react-ga4';
import PrivateRoute from 'components/PrivateRoute';

export default function App() {
  const auth = useAuthContext();
  return (
    <BrowserRouter>
      {/* <Helmet titleTemplate='%s'>
      <meta name='description' content='Schext is a powerful text based appiointment scheduler!' />
    </Helmet> */}
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route
            index
            element={auth.isAuthenticated() ? <Dashboard /> : <Home />}
          />
          <Route path="/loggedout" element={<Home loggedOut={true} />} />
          <Route path="/signin">
            <Route index element={<SignIn />} />
            <Route path="verification" element={<Verification />} />
          </Route>
          <Route path="/signup" element={<SignUp />} />
          <Route
            path="/dashboard/*"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          ></Route>
          <Route path="*" element={<div>Not Found</div>} />
        </Route>
      </Routes>
      {/* </AuthProvider> */}
    </BrowserRouter>
  );
}

import {
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UpdateScheduleRequest } from '../../../api/models/schedule';
import { scheduleApi } from '../../../api/schedules';
import TimePicker from '../../../components/TimePicker';

interface Props {
  day: any;
  isOpen: boolean;
  onClose: () => void;
}

export default function AddScheduleModal({ day, isOpen, onClose }: Props) {
  const { business } = useParams();
  const id = business?.split('&')[0];
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [currentDay, setCurentDay] = useState(day?.dayOfweek);

  const handleStartTime = (time) => {
    setStartTime(time);
  };

  const handleEndTime = (time) => {
    setEndTime(time);
  };

  useEffect(() => {
    setCurentDay(day?.dayOfWeek);
  }, [day]);

  const formik = useFormik({
    initialValues: {
      dayOfWeek: 0,
      startTime: '',
      endTime: '',
      organizationId: parseInt(id!),
    },
    onSubmit: async (values) => {
      const request: UpdateScheduleRequest = { ...values };

      const start = new Date('01/01/2001 ' + startTime).getTime();
      const end = new Date('01/01/2001 ' + endTime).getTime();

      request.dayOfWeek = currentDay;

      if (end <= start) {
        formik.errors.endTime =
          'End time cannot before or the same as start time';
      } else {
        request.startTime = startTime;
        request.endTime = endTime;
        const response = await scheduleApi.updateSchedule(request);
        if (response.response === 200) {
          formik.resetForm();
          onClose();
        } else {
          console.log('Could not update the day selected');
        }
      }
    },
  });

  const deleteClient = (dayId) => {
    scheduleApi.deleteSchedule(dayId);
    window.location.reload();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      isCentered
      size={'lg'}
    >
      <ModalOverlay />
      <form onSubmit={formik.handleSubmit}>
        <ModalContent>
          <ModalHeader {...styles.header}>
            Edit {getDay(DayOfWeek, day?.dayOfWeek)}
          </ModalHeader>
          <ModalCloseButton color={'black'} />
          <ModalBody>
            <TimePicker
              handleStartTime={handleStartTime}
              handleEndTime={handleEndTime}
            />
            <ButtonGroup style={{ marginTop: 20 }}>
              <Button
                {...styles.deleteButton}
                onClick={() => deleteClient(day?.id)}
              >
                Delete
              </Button>
              <ButtonGroup style={{ marginLeft: 125 }}>
                <Button {...styles.cancelButton} onClick={onClose}>
                  Cancel
                </Button>
                <Button {...styles.submitButton} type="submit">
                  Update
                </Button>
              </ButtonGroup>
            </ButtonGroup>
            <FormControl
              isInvalid={!!formik.errors.endTime && formik.touched.endTime}
            >
              <FormErrorMessage>{formik.errors.endTime}</FormErrorMessage>
            </FormControl>
          </ModalBody>
        </ModalContent>
      </form>
    </Modal>
  );
}

const styles = {
  header: {
    color: 'black',
    fontWeight: 'bold',
  },
  label: {
    color: 'black',
  },
  submitButton: {
    backgroundColor: 'rgba(0, 0, 0, .2)',
    color: 'white',
    width: 130,
  },
  cancelButton: {
    border: '1px solid',
    borderColor: 'grey',
    backgroundColor: 'transparent',
    color: 'black',
    width: 100,
  },
  deleteButton: {
    border: '1px solid',
    borderColor: 'grey',
    backgroundColor: '#d9534f',
    color: 'color',
    width: 100,
  },
};

enum DayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

function getDay<T>(type: T, day: number): T[keyof T] {
  const casted = day as keyof T;
  return type[casted];
}

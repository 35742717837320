import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { organizationApi } from 'api/organizations';

interface Props {
  id: number;
  name: string;
  isOpen: boolean;
  onClose: () => void;
}

export default function DeleteBusinessModal({
  id,
  name,
  isOpen,
  onClose,
}: Props) {
  const handleDelete = (organizationId) => {
    organizationApi.deleteOrganization(organizationId);
    window.location.reload();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      isCentered
      size="md"
    >
      <ModalOverlay />
      <form>
        <ModalContent>
          <ModalHeader color="black" textAlign="center" fontWeight="bold">
            Are you sure you want to delete {name}?
          </ModalHeader>
          <ModalBody color="black" textAlign="center" fontWeight="semibold">
            Deleting <b>{name}</b> will remove it from your account. This action
            will also remove any and all clients associated to <b>{name}</b>!
          </ModalBody>

          <ModalFooter>
            <ButtonGroup>
              <Button background="gray.400" color="white" onClick={onClose}>
                Cancel
              </Button>
              <Button
                background="red.500"
                colorScheme="red"
                onClick={() => handleDelete(id)}
              >
                Delete
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
}

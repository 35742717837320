import {
  Divider,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  typography,
  Button,
  Tooltip,
} from '@chakra-ui/react';
import { useAuthContext } from 'context/AuthContext';
import { useState } from 'react';
import { AiOutlineExport, AiOutlineQuestionCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

interface Props {
  userName: string;
  userEmail: string;
}

export default function DropDownMenu({ userName, userEmail }: Props) {
  const auth = useAuthContext();
  const navigate = useNavigate();

  const logOut = () => {
    auth.signUserOut();

    navigate('/loggedout');
  };

  return (
    <MenuList color="black" textAlign="center">
      <MenuGroup
        justifyContent="center"
        textAlign="center"
        alignContent="center"
      >
        <MenuGroup
          title={userName}
          sx={{ fontWeight: 'bold', fontSize: 20, alignContent: 'center' }}
        />
        <MenuGroup title={userEmail} sx={{ fontSize: 16 }} />
      </MenuGroup>
      <MenuDivider />
      <MenuGroup>
        <MenuItem
          as={Button}
          onClick={logOut}
          icon={<AiOutlineExport size={20} />}
          color="#3f3f3f"
        >
          Log out
        </MenuItem>
        <Tooltip label="Email us at support@schext.com">
          <MenuItem
            aria-label="Email us at support@schext.com"
            as={Button}
            onClick={() => (window.location.href = 'mailto:support@schext.com')}
            icon={<AiOutlineQuestionCircle size={20} />}
            color="#3f3f3f"
          >
            Support
          </MenuItem>
        </Tooltip>
      </MenuGroup>
    </MenuList>
  );
}

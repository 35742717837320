import { Input as ChakraInput, InputProps } from '@chakra-ui/react';

interface props extends InputProps {
  mask?: string;
  maskPlaceholder?: string;
}

export default function Input(props: props) {
  return <ChakraInput {...styles.input} {...props} />;
}

const styles = {
  input: {
    borderColor: 'rgb(255, 255, 255, 0.2)',
    focusBorderColor: 'white',
    errorBorderColor: 'pink.300',
    variant: 'flushed',
    sx: {
      '::placeholder': {
        color: 'white',
      },
    },
  },
};

import { CreateOrganizationRequest, Organization } from './models/organization';

const API = process.env.REACT_APP_API_URL;

export const cleanPhoneNumber = (phone: string) =>
  '+1' + phone.replace(/[- )(]/g, '');

export const organizationApi = {
  async createOrganization(data: CreateOrganizationRequest) {
    const response = await fetch(`${API}/organizations/`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(data),
    });
    return response.json();
  },

  async deleteOrganization(organizationId: number) {
    const response = await fetch(`${API}/organizations/${organizationId}`, {
      method: 'DELETE',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    });
    return response.json();
  },

  async getOrganizations(userId: number) {
    const response = await fetch(`${API}/organizations/${userId}`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    });
    return await response.json();
  },

  async getOrganizationByIdAndName(
    organizationId: number,
    organizationName: string
  ) {
    const response = await fetch(
      `${API}/organizations/${organizationId}/${organizationName}`,
      {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
      }
    );
    return await response.json();
  },
};

import {
  Box,
  Container,
  Heading,
  Link,
  SimpleGrid,
  Stack,
} from '@chakra-ui/react';
import { a } from 'aws-amplify';
import { Link as RouterLink } from 'react-router-dom';

export default function Footer() {
  return (
    <Box as="footer">
      <Container as={Stack} {...styles.container}>
        <SimpleGrid {...styles.grid}>
          <Stack spacing={4}>
            {/* TODO: Add an about page later */}
            {/* <Link as={RouterLink} {...styles.link} to="/about">
              About
            </Link> */}
            <a
              style={{ fontWeight: '500' }}
              href="mailto:schext.info@gmail.com"
            >
              Contact
            </a>
            <Link as={RouterLink} {...styles.link} to="/signup">
              Sign Up
            </Link>
            <Link as={RouterLink} {...styles.link} to="/signin">
              Sign In
            </Link>
          </Stack>
          <Stack>
            <a
              style={{ fontWeight: '500' }}
              target="_blank"
              href="https://schext-legal.s3.us-east-2.amazonaws.com/online-terms-and-conditions.pdf"
            >
              Terms and Conditions
            </a>
            <a
              style={{ fontWeight: '500' }}
              target="_blank"
              href="https://schext-legal.s3.us-east-2.amazonaws.com/online-privacy-policy.pdf"
            >
              Privacy Policy
            </a>
          </Stack>
        </SimpleGrid>
        <Stack {...styles.logo}>
          <Heading size="md">
            s<span>chext</span>
          </Heading>
        </Stack>
      </Container>
    </Box>
  );
}

const styles = {
  container: {
    size: 'lg',
    p: 8,
    justify: 'space-between',
    align: 'center',
    direction: 'row',
  },
  grid: {
    columns: { base: 1, sm: 2 },
    spacing: 8,
    color: 'white',
  },
  link: {
    fontWeight: '500',
    mb: 2,
  },
  logo: {
    justifySelf: { base: 'flex-start', sm: 'flex-end' },
    alignSelf: 'center',
    sx: {
      span: {
        fontWeight: 'normal',
      },
    },
  },
};

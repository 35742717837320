import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Container,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  StackDirection,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { clientApi } from '../../api/clients';
import { useAuthContext } from '../../context/AuthContext';
import AddClientModal from './AddClientModal';
import AppointmentExistModal from './AppointmentExistModal';
import EditClientModal from './EditClientModal';

export default function ClientsPage() {
  const auth = useAuthContext();
  const { business } = useParams();
  const id = business?.split('&')[0];
  const [clients, setClients] = useState<any>();
  const [isOpen, setIsOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [editModalData, setEditModalData] = useState<any>();
  const [isAppointmentExistOpen, setIsAppointmentExistOpen] = useState(false);

  useEffect(() => {
    (async () => {
      const result = await clientApi.getClients(parseInt(id!));
      setClients(result.clients);
    })();
  }, [isOpen, isEditOpen]);

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const deleteClient = async (clientId) => {
    const appointmentExist = await clientApi.deleteClient(clientId);
    if (appointmentExist.response === 409) {
      setIsAppointmentExistOpen(true);
    } else {
      window.location.reload();
    }
  };

  const onOpenEdit = (client) => {
    setEditModalData(client);
    setIsEditOpen(true);
  };

  const onCloseEdit = () => {
    setIsEditOpen(false);
  };

  const onCloseAppointmentExist = () => {
    setIsAppointmentExistOpen(false);
  };

  return (
    <>
      <Stack style={{ alignItems: 'end' }}>
        <Button
          variant={'glass'}
          {...styles.addButton}
          textAlign={'right'}
          onClick={onOpen}
        >
          + Add Client
        </Button>
        <AddClientModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
      </Stack>
      {clients?.map((client) => {
        return (
          <Box {...styles.clientBox} key={client.id}>
            <Stack {...styles.stack}>
              <Button {...styles.profileButton} pointerEvents="none">
                {client.name.charAt(0).toUpperCase()}
              </Button>
              <Heading {...styles.clientInfo}>{client.name}</Heading>
              <Heading {...styles.clientInfo}>
                {client.phone.replace(
                  /^\+[1](\d{3})(\d{3})(\d{4})/,
                  '($1) $2 $3'
                )}
              </Heading>
              <Menu>
                <MenuButton
                  {...styles.clientOptions}
                  as={IconButton}
                  icon={<ChevronDownIcon />}
                />
                <MenuList>
                  <MenuItem
                    {...styles.clientOptionsItem}
                    as="button"
                    onClick={() => onOpenEdit(client)}
                  >
                    Edit Client
                  </MenuItem>
                  <MenuItem
                    {...styles.clientOptionsItem}
                    as={'button'}
                    onClick={() => deleteClient(client.id)}
                  >
                    Delete
                  </MenuItem>
                </MenuList>
              </Menu>
            </Stack>
          </Box>
        );
      })}
      <EditClientModal
        client={editModalData}
        isOpen={isEditOpen}
        onClose={onCloseEdit}
      />
      <AppointmentExistModal
        isOpen={isAppointmentExistOpen}
        onClose={onCloseAppointmentExist}
      />
    </>
  );
}

const styles = {
  header: {
    fontSize: 32,
    marginBottom: -5,
    sx: {
      span: {
        fontWeight: 'normal',
      },
    },
  },
  addButton: {
    width: 125,
    marginBottom: 10,
  },
  clientBox: {
    backgroundColor: 'rgb(255, 255, 255, 0.2)',
    width: '100%',
    borderRadius: 5,
    marginTop: 5,
  },
  stack: {
    color: 'white',
    spacing: 8,
    direction: ['column', 'column', 'row'] as StackDirection,
    align: ['flex-start', 'center'],
    justify: 'space-between',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 5,
    paddingBottom: 5,
  },
  clientInfo: {
    fontSize: 24,
  },
  profileButton: {
    width: ['100%', '100%', 50],
    borderRadius: [5, 5, 25],
    marginLeft: [0, 0, -5],
  },
  clientOptions: {
    backgroundColor: ['white', 'white', 'transparent'],
    width: ['100%', '100%', 10],
    color: ['grey', 'grey', 'white'],
  },
  clientOptionsItem: {
    _hover: {
      backgroundColor: 'rgba(0,0,0,.1)',
    },
    color: 'black',
  },
};

import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { appointmentApi } from 'api/appointments';

interface Props {
  appointmentId: number;
  isOpen: boolean;
  onClose: () => void;
}

export default function CancelAppointmentModal({
  appointmentId,
  isOpen,
  onClose,
}: Props) {
  const cancelAppointment = (appointmentId) => {
    appointmentApi.cancelAppointment(appointmentId);
    window.location.reload();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalOverlay />
      <form>
        <ModalContent>
          <ModalHeader color={'black'}>Cancel Appointment?</ModalHeader>
          <ModalCloseButton color={'black'} />
          <ModalBody>
            <Text textAlign={'center'} color={'black'}>
              After cancelling this appointment, this cannot be undone. Are you
              sure you want to cancel?
            </Text>
            <ButtonGroup style={{ marginTop: 20, display: 'flex' }}>
              <Button {...styles.backButton} onClick={onClose}>
                Back
              </Button>
              <Button
                {...styles.cancelButton}
                onClick={() => cancelAppointment(appointmentId)}
              >
                Cancel Appointment
              </Button>
            </ButtonGroup>
          </ModalBody>
        </ModalContent>
      </form>
    </Modal>
  );
}

const styles = {
  cancelButton: {
    backgroundColor: '#d9534f',
    color: 'white',
    width: '100%',
  },
  backButton: {
    border: '1px solid',
    borderColor: 'grey',
    backgroundColor: 'transparent',
    color: 'black',
    width: 100,
  },
};

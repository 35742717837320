import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  GridItem,
  Heading,
  Stack,
  StackDirection,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Link, Route, Routes, useParams } from 'react-router-dom';
import { appointmentApi } from '../../api/appointments';
import { organizationApi } from '../../api/organizations';
import { useAuthContext } from '../../context/AuthContext';
import ClientsPage from '../ClientsPage';
import SchedulePage from '../SchedulePage';
import CancelAppointmentModal from './CancelAppointmentModal';
import { isMobile } from 'react-device-detect';
import ContactModal from './ContactModal';

function BusinessDashboardpage() {
  const auth = useAuthContext();
  const { business } = useParams();
  const id = business?.split('&')[0];
  const name = business?.split('&')[1];
  const [organization, setOrganization] = useState<any>();
  const [appointments, setAppointments] = useState<any>();
  const [upcomingAppointment, setUpcomingAppointment] = useState<any>();
  const [isCancelOpen, setIsCancelOpen] = useState(false);
  const [isContactOpen, setIsContactOpen] = useState(false);
  const [appointmentId, setAppointmentId] = useState(0);
  const [phone, setPhone] = useState<any>();

  useEffect(() => {
    (async () => {
      const result = await organizationApi.getOrganizationByIdAndName(
        parseInt(id!),
        name!
      );
      setOrganization(result.organization);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const result = await appointmentApi.getAppointments(parseInt(id!));
      const sortedResult = result.appointments?.sort((a, b) =>
        (a.date & a.startTime) > (b.date & b.startTime) ? -1 : 1
      );
      setUpcomingAppointment(sortedResult[0]);
      setAppointments(sortedResult.slice(1));
    })();
  }, []);

  const convertDate = (givenDate, givenTime) => {
    const givenDateTime = new Date(givenDate + ' ' + givenTime);
    return givenDateTime.toLocaleString('en-US', {
      month: 'long',
      day: 'numeric',
    });
  };

  const convertTime = (givenDate, givenTime) => {
    const givenDateTime = new Date(givenDate + ' ' + givenTime);
    return givenDateTime.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  };

  const contactButton = (phone) => {
    if (isMobile) {
      window.location.href = `tel:${phone}`;
    } else {
      setPhone(phone);
      setIsContactOpen(true);
    }
  };

  const onCloseContact = () => {
    setIsContactOpen(false);
  };

  const onOpenCancel = (appointmentId) => {
    setAppointmentId(appointmentId);
    setIsCancelOpen(true);
  };

  const onCloseCancel = () => {
    setIsCancelOpen(false);
  };

  return (
    <>
      <Stack
        {...styles.stack}
        direction={{ base: 'column', sm: 'column', md: 'column', lg: 'row' }}
        justifyContent={'center'}
      >
        <Stack {...styles.textStack}>
          <Heading {...styles.header} textAlign="center">
            Next Appointment
          </Heading>
          <Box {...styles.glassContainer}>
            {upcomingAppointment ? (
              <Stack direction={'column'}>
                <Text style={{ fontSize: 30, fontWeight: 'bold' }}>
                  {upcomingAppointment?.client.name}
                </Text>
                <Stack
                  direction={'row'}
                  style={{ justifyContent: 'center', fontSize: 25 }}
                >
                  <Text>
                    {convertDate(
                      upcomingAppointment?.date,
                      upcomingAppointment?.startTime
                    )}{' '}
                    |{' '}
                  </Text>
                  <Text>
                    {convertTime(
                      upcomingAppointment?.date,
                      upcomingAppointment?.startTime
                    )}
                  </Text>
                </Stack>
                <ButtonGroup style={{ marginTop: 20 }}>
                  <Button
                    {...styles.contactButton}
                    onClick={() =>
                      contactButton(upcomingAppointment?.client.phone)
                    }
                  >
                    Contact
                  </Button>
                  <Button onClick={() => onOpenCancel(upcomingAppointment?.id)}>
                    Cancel
                  </Button>
                </ButtonGroup>
              </Stack>
            ) : (
              <Text>No Upcoming Appointments</Text>
            )}
          </Box>
        </Stack>
        <Stack
          direction={{ base: 'column', sm: 'row', md: 'row', lg: 'column' }}
          style={{ marginTop: 50 }}
        >
          <Button as={Link} to="schedule">
            Schedule
          </Button>
          <Button as={Link} to="clients" variant={'glass'}>
            Clients
          </Button>
        </Stack>
      </Stack>
      <ContactModal
        phone={phone}
        isOpen={isContactOpen}
        onClose={onCloseContact}
      />
      <Box {...styles.allAppointmentsBox}>
        <Heading textAlign={'center'} pb={6}>
          Upcoming Appointments
        </Heading>
        <Grid
          gap={6}
          templateColumns={{
            base: 'repeat(1, 500px)',
            lg: 'repeat(2, 1fr)',
          }}
          justifyContent={'center'}
          justifyItems={'center'}
        >
          {appointments?.map((appointment) => {
            return (
              <GridItem key={appointment?.id}>
                <Box key={appointment?.id} {...styles.appointmentBox}>
                  <Stack
                    direction={'row'}
                    style={{ justifyContent: 'space-between' }}
                  >
                    <Stack direction={'column'}>
                      <Text {...styles.clientName} textTransform={'capitalize'}>
                        {appointment?.client.name}
                      </Text>
                      <Text>
                        {convertDate(appointment?.date, appointment?.startTime)}{' '}
                        |{' '}
                        {convertTime(appointment?.date, appointment?.startTime)}
                      </Text>
                      {appointment?.notes ? <Text></Text> : null}
                    </Stack>
                    <Stack direction={'column'} style={{ right: 0 }}>
                      <Button
                        {...styles.allContactButton}
                        variant={'outline'}
                        onClick={() => contactButton(appointment?.client.phone)}
                      >
                        Contact
                      </Button>
                      <Button
                        {...styles.allContactButton}
                        onClick={() => onOpenCancel(appointment?.id)}
                      >
                        Cancel
                      </Button>
                    </Stack>
                  </Stack>
                </Box>
              </GridItem>
            );
          })}
        </Grid>
      </Box>
      <CancelAppointmentModal
        appointmentId={appointmentId}
        isOpen={isCancelOpen}
        onClose={onCloseCancel}
      />
    </>
  );
}

export default function BusinessDashboard() {
  return (
    <Routes>
      <Route index element={<BusinessDashboardpage />} />
      <Route path="clients/*" element={<ClientsPage />} />
      <Route path="schedule/*" element={<SchedulePage />} />
    </Routes>
  );
}

const styles = {
  stack: {
    color: 'white',
    spacing: 8,
    align: ['flex-start', 'center'],
    justify: 'center',
    alignItems: 'center',
  },
  header: {
    fontSize: 32,
    marginBottom: -5,
    sx: {
      span: {
        fontWeight: 'normal',
      },
    },
  },
  glassContainer: {
    backgroundColor: 'rgb(255, 255, 255, 0.2)',
    p: 10,
    align: 'center',
    rounded: 'lg',
    size: 'sm',
    width: ['100%', 450],
  },
  contactButton: {
    background: 'transparent',
    border: '1px solid white',
    color: 'white',
    _hover: {
      background: 'transparent',
      border: '1px solid white',
      color: 'white',
    },
  },
  textStack: {
    spacing: [5, 10],
  },
  container: {
    size: 'lg',
  },
  allAppointmentsBox: {
    alignItems: 'center',
    marginTop: 100,
  },
  appointmentBox: {
    padding: 2,
    border: '1px solid white',
    borderRadius: 5,
    width: 400,
  },
  clientName: {
    fontWeight: 'bold',
    fontSize: 24,
  },
  allContactButton: {
    width: '100%',
  },
};

import React, { useEffect } from 'react';
import { Container, useFocusEffect } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import { useAuthContext } from '../../context/AuthContext';
import AuthHeader from './AuthHeader';

interface props {
  children?: React.ReactNode;
}

// TODO: Verify width of wrapper is good for large screens
export default function Layout({ children }: props) {
  const auth = useAuthContext();
  const authStatus = auth.isAuthenticated();

  return (
    <Container maxW="container.xl">
      {authStatus ? <AuthHeader /> : <Header />}
      <main>
        <Outlet />
      </main>
      {authStatus ? null : <Footer />}
    </Container>
  );
}

import { CreateClientRequest, UpdateClientRequest } from './models/client';

const API = process.env.REACT_APP_API_URL;

export const cleanPhoneNumber = (phone: string) =>
  '+1' + phone.replace(/[- )(]/g, '');

export const clientApi = {
  async getClients(organizationId: number) {
    const response = await fetch(`${API}/clients/${organizationId}`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    });
    return await response.json();
  },

  async createClient(data: CreateClientRequest) {
    const response = await fetch(`${API}/clients/`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(data),
    });
    return response.json();
  },

  async deleteClient(clientId: number) {
    const response = await fetch(`${API}/clients/${clientId}`, {
      method: 'DELETE',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    });
    return await response.json();
  },

  async updateClient(data: UpdateClientRequest) {
    const response = await fetch(`${API}/clients/`, {
      method: 'PUT',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(data),
    });
    return await response.json();
  },

  async checkClientExist(phone: string) {
    const response = await fetch(`${API}/clients/client-exist`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(phone),
    });
    return response.json();
  },
};

import {
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  SelectField,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PassThrough } from 'stream';
import { number } from 'yup/lib/locale';
import {
  AddScheduleRequest,
  Schedule,
  UpdateScheduleRequest,
} from '../../../api/models/schedule';
import { scheduleApi } from '../../../api/schedules';
import TimePicker from '../../../components/TimePicker';

interface Props {
  schedules: Schedule[];
  isOpen: boolean;
  onClose: () => void;
}

export default function AddScheduleModal({
  schedules,
  isOpen,
  onClose,
}: Props) {
  const { business } = useParams();
  const id = business?.split('&')[0];
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const currentDays: any = [];
  const [openDays, setOpenDays] = useState<any>([]);

  useEffect(() => {
    const tempOpenDays: any = [];
    schedules?.map((schedule) => {
      currentDays.push(schedule.dayOfWeek);
    });
    for (let i = 0; i < 7; i++) {
      if (currentDays.includes(i)) {
        //pass;
      } else {
        tempOpenDays.push(i);
      }
    }
    setOpenDays(tempOpenDays);
  }, [isOpen]);

  const handleStartTime = (time) => {
    setStartTime(time);
  };

  const handleEndTime = (time) => {
    setEndTime(time);
  };

  useEffect(() => {
    setStartTime(startTime);
  }, [startTime]);

  useEffect(() => {
    setEndTime(endTime);
  }, [endTime]);

  const formik = useFormik({
    initialValues: {
      dayOfWeek: 0,
      startTime: startTime,
      endTime: endTime,
      organizationId: parseInt(id!),
    },
    onSubmit: async (values) => {
      const request: AddScheduleRequest = { ...values };

      const start = new Date('01/01/2001 ' + startTime).getTime();
      const end = new Date('01/01/2001 ' + endTime).getTime();

      if (end <= start) {
        formik.errors.endTime =
          'End time cannot before or the same as start time';
      } else {
        request.startTime = startTime;
        request.endTime = endTime;
        const response = await scheduleApi.addSchedule(request);
        if (response.response === 200) {
          formik.resetForm();
          onClose();
        } else {
          console.log('Could not add the day selected');
        }
      }
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      isCentered
      size={'lg'}
    >
      <ModalOverlay />
      <form onSubmit={formik.handleSubmit}>
        <ModalContent>
          <ModalHeader {...styles.header}>Add Day to Schedule</ModalHeader>
          <ModalCloseButton color={'black'} />
          <ModalBody>
            <Select
              placeholder="Select day"
              {...styles.daySelector}
              onChange={formik.handleChange}
              id="dayOfWeek"
              name="dayOfWeek"
              required
            >
              {openDays.map((openDay) => {
                return (
                  <option key={openDay} value={openDay}>
                    {getDay(DayOfWeek, openDay)}
                  </option>
                );
              })}
              ;
            </Select>
            <TimePicker
              handleStartTime={handleStartTime}
              handleEndTime={handleEndTime}
            />
            <ButtonGroup style={{ marginTop: 20 }}>
              <Button {...styles.cancelButton} onClick={onClose}>
                Cancel
              </Button>
              <Button {...styles.submitButton} type="submit">
                Submit
              </Button>
            </ButtonGroup>
            <FormControl
              isInvalid={!!formik.errors.endTime && formik.touched.endTime}
            >
              <FormErrorMessage>{formik.errors.endTime}</FormErrorMessage>
            </FormControl>
          </ModalBody>
        </ModalContent>
      </form>
    </Modal>
  );
}

const styles = {
  header: {
    color: 'black',
    fontWeight: 'bold',
  },
  label: {
    color: 'black',
  },
  submitButton: {
    backgroundColor: 'rgba(0, 0, 0, .2)',
    color: 'white',
    width: 130,
  },
  cancelButton: {
    border: '1px solid',
    borderColor: 'grey',
    backgroundColor: 'transparent',
    color: 'black',
    width: 100,
  },
  daySelector: {
    color: 'black',
    width: 150,
    marginBottom: 5,
  },
};

enum DayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

function getDay<T>(type: T, day: number): T[keyof T] {
  const casted = day as keyof T;
  return type[casted];
}

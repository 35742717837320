import { ComponentStyleConfig } from '@chakra-ui/react';

// All Container overrides
const Container: ComponentStyleConfig = {
  baseStyle: {
    maxWidth: '100%',
  },
  sizes: {
    lg: {
      maxWidth: '1200px',
    },
    md: {
      maxWidth: '960px',
    },
    sm: {
      maxWidth: '720px',
    },
  },
};

export default Container;

import {
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import regex from '../../../utils/regex';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { UpdateClientRequest } from '../../../api/models/client';
import { cleanPhoneNumber, clientApi } from '../../../api/clients';
import InputMask from 'react-input-mask';
import { useState } from 'react';

interface Props {
  client?: any;
  isOpen: boolean;
  onClose: () => void;
}

export default function EditClientModal({ client, isOpen, onClose }: Props) {
  const { business } = useParams();
  const id = business?.split('&')[0];
  const formattedPhone = client?.phone.replace(
    /^\+[1](\d{3})(\d{3})(\d{4})/,
    '($1) $2 $3'
  );

  const AddClientSchema = Yup.object().shape({
    name: Yup.string().trim().min(3, 'Name must be more than 3 characters'),
    // .required('Please enter a name for your client'),
    phone: Yup.string()
      .trim()
      .matches(regex.phone, 'Must be a valid phone number')
      .min(14, 'Must be a valid phone number'),
    // .required('Please enter a phone number'),
  });

  const formik = useFormik({
    initialValues: {
      id: client?.id,
      name: `${client?.name}`,
      phone: `${formattedPhone}`,
    },
    enableReinitialize: true,
    validationSchema: AddClientSchema,
    onSubmit: async (values) => {
      const request: UpdateClientRequest = { ...values };
      request.phone = cleanPhoneNumber(values.phone);

      const response = await clientApi.updateClient(request);
      if (response.response === 200) {
        formik.resetForm();
        onClose();
        window.location.reload();
      } else {
        console.log('Could not update client');
      }
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalOverlay />
      <form onSubmit={formik.handleSubmit}>
        <ModalContent>
          <ModalHeader {...styles.header}>Update Client</ModalHeader>
          <ModalCloseButton color={'black'} />
          <ModalBody>
            <FormControl
              isInvalid={!!formik.errors.name && formik.touched.name}
            >
              <FormLabel htmlFor="name" {...styles.label}>
                Name
              </FormLabel>
              <Input
                id="name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                color="black"
              />
              <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={!!formik.errors.phone && formik.touched.phone}
            >
              <FormLabel htmlFor="phone" {...styles.label}>
                Phone Number
              </FormLabel>
              <Input
                as={InputMask}
                mask={'(999) 999 9999'}
                maskPlaceholder=""
                id="phone"
                type="tel"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                color="black"
              />
              <FormErrorMessage>{formik.errors.phone}</FormErrorMessage>
            </FormControl>
            <ButtonGroup style={{ marginTop: 20 }}>
              <Button {...styles.cancelButton} onClick={onClose}>
                Cancel
              </Button>
              <Button {...styles.submitButton} type="submit">
                Update Client
              </Button>
            </ButtonGroup>
          </ModalBody>
        </ModalContent>
      </form>
    </Modal>
  );
}

const styles = {
  header: {
    color: 'black',
    fontWeight: 'bold',
  },
  label: {
    color: 'black',
  },
  submitButton: {
    backgroundColor: 'rgba(0, 0, 0, .2)',
    color: 'white',
    width: 130,
  },
  cancelButton: {
    border: '1px solid',
    borderColor: 'grey',
    backgroundColor: 'transparent',
    color: 'black',
    width: 100,
  },
};

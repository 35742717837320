import {
  Button,
  Container,
  FormControl,
  FormErrorMessage,
  Heading,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
import { CreateUserRequest } from '../../api/models/user';
import { cleanPhoneNumber, userApi } from '../../api/users';
import regex from '../../utils/regex';
import Input from '../../components/Input';
import { useAuthContext } from '../../context/AuthContext';

export default function SignUpPage() {
  const navigate = useNavigate();
  const authContext = useAuthContext();

  const SignUpSchema = Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .matches(
        regex.name,
        "Only the following characters are allowed: /^[a-z ,.'-]+$/i"
      )
      .required('Please enter a first name'),
    lastName: Yup.string()
      .trim()
      .matches(
        regex.name,
        "Only the following characters are allowed: /^[a-z ,.'-]+$/i"
      )
      .required('Please enter a last name'),
    email: Yup.string()
      .email('Enter a valid email')
      .required('Please enter an email'),
    phone: Yup.string()
      .matches(regex.phone, 'Must be a valid phone number')
      .min(14, 'Must be a valid phone number')
      .required('Please enter a phone number'),
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    },
    validationSchema: SignUpSchema,
    onSubmit: async (values) => {
      const request: CreateUserRequest = { ...values };
      request.phone = cleanPhoneNumber(values.phone);

      const userPhoneExist = await userApi.checkUserExistPhone(request.phone);
      const userEmailExist = await userApi.checkUserExistEmail(values.email);

      if (userPhoneExist.response === 404 && userEmailExist.response === 404) {
        await userApi.createUser(request).then((data) => {
          console.log(data);
        });

        authContext.initiateChallenge(request.phone);
        navigate('/signin/verification');
      } else if (userPhoneExist.response === 409) {
        formik.errors.phone =
          'An account with this phone number already exists';
      } else if (userEmailExist.response === 409) {
        formik.errors.email = 'An account with this email already exists';
      }
    },
  });

  const formFields = [
    {
      name: 'firstName',
      label: 'First Name',
      type: 'text',
    },
    {
      name: 'lastName',
      label: 'Last Name',
      type: 'text',
    },
    {
      name: 'email',
      label: 'Email',
      type: 'email',
    },
    {
      name: 'phone',
      label: 'Phone',
      type: 'tel',
      props: {
        as: InputMask,
        mask: '(999) 999 9999',
        maskPlaceholder: '',
      },
    },
  ];

  return (
    <Container {...styles.container}>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={8}>
          <Heading>Create an account</Heading>
          {formFields.map((field) => (
            <FormControl
              isInvalid={
                !!formik.errors[field.name] && formik.touched[field.name]
              }
              key={field.name}
            >
              <Input
                id={field.name}
                type={field.type}
                name={field.name}
                placeholder={field.label}
                value={formik.values[field.name]}
                onChange={formik.handleChange}
                {...field.props}
              />
              <FormErrorMessage {...styles.errorMessage}>
                {formik.errors[field.name]}
              </FormErrorMessage>
            </FormControl>
          ))}
          <Button {...styles.btn} type="submit">
            Create Account
          </Button>
          <Text>
            By creating an account you agree to receive automated messages at
            the number provided; you also agree to our{' '}
            <Link as={RouterLink} to="/terms">
              Terms and Conditions
            </Link>{' '}
            and{' '}
            <Link as={RouterLink} to="/privacy">
              Privacy Policy
            </Link>
          </Text>
        </Stack>
      </form>
    </Container>
  );
}

const styles = {
  container: {
    color: 'white',
    size: 'sm',
    align: 'center',
    minHeight: 'calc(100vh - 300px)',
    py: 20,
    sx: {
      a: {
        fontWeight: 'bold',
      },
    },
  },
  btn: {
    alignSelf: 'center',
  },
  errorMessage: {
    color: 'pink',
    p: 1,
    fontWeight: 'bold',
  },
};

import {
  Flex,
  Button,
  Heading,
  Icon,
  Text,
  Stack,
  Menu,
  MenuButton,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../context/AuthContext';
import { IconButton } from '@chakra-ui/react';
import { AiOutlineHome } from 'react-icons/ai';
import DropDownMenu from 'components/DropDownMenu';
import { HamburgerIcon } from '@chakra-ui/icons';

export default function AuthHeader() {
  const auth = useAuthContext();
  const fullName =
    auth.userProfile?.first_name + ' ' + auth.userProfile?.last_name;
  const navigate = useNavigate();
  const business = window.location.pathname;
  const [onBusinessDashboard, setOnBusinessDashboard] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (business.includes('&')) {
      setOnBusinessDashboard(true);
    } else {
      setOnBusinessDashboard(false);
    }
  }, [location]);

  const navigateHome = () => {
    const newURL = '/' + business.split('/')[1] + '/' + business.split('/')[2];
    navigate(newURL);
  };

  return (
    <Flex as="header" {...styles.container}>
      <Heading as={RouterLink} {...styles.header} to="/dashboard">
        <span>s</span>chext
      </Heading>
      <Flex as="nav" {...styles.nav}>
        {onBusinessDashboard && (
          <Stack
            spacing={0}
            {...styles.homeStack}
            _hover={{ bg: 'rgba(200,200,200,0.2)' }}
            _focus={{ boxShadow: 'outline' }}
          >
            <IconButton
              aria-label="your organization home"
              {...styles.homeIcon}
              onClick={navigateHome}
              colorScheme="white"
              variant="outline"
            >
              <Icon as={AiOutlineHome} />
            </IconButton>
          </Stack>
        )}
        <Menu>
          <MenuButton
            icon={<HamburgerIcon />}
            as={IconButton}
            {...styles.profileButton}
            aria-label="Profile Menu"
            variant="outline"
          />
          <DropDownMenu
            userName={fullName}
            userEmail={auth.userProfile!.email}
          />
        </Menu>
      </Flex>
    </Flex>
  );
}

const styles = {
  header: {
    fontWeight: 'normal',
    size: 'lg',
    alignSelf: 'center',
    sx: {
      span: {
        fontWeight: 'bold',
      },
    },
  },
  homeStack: {
    display: 'flex',
    alignItems: 'center',
  },
  homeIcon: {
    width: 10,
    height: 10,
    _hover: {
      color: 'white',
    },
  },
  profileName: {
    size: 'md',
  },
  container: {
    color: 'white',
    p: 6,
    justify: 'space-between',
    w: '100%',
  },
  nav: {
    align: 'center',
    gap: 2,
  },
  profileButton: {
    width: '100%',
  },
  cta: {
    variant: 'outline',
    size: 'md',
    _hover: { color: 'black', bg: 'white' },
    fontWeight: 'normal',
  },
};

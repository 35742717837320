import React, { useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Select,
  Spinner,
  Stack,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Input from '../../components/Input';
import { useFormik } from 'formik';
import InputMask from 'react-input-mask';
import { useAuthContext } from '../../context/AuthContext';
import * as Yup from 'yup';
import regex from '../../utils/regex';
import { CreateOrganizationRequest } from '../../api/models/organization';
import { cleanPhoneNumber, organizationApi } from '../../api/organizations';
import { TimezoneApi, TimezoneResponse } from 'api/timezones';
import { Async } from 'react-async';

export default function NewBusinessForm() {
  const auth = useAuthContext();
  const navigate = useNavigate();

  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [localRegion, localPlace] = localTimezone.split('/', 2);

  const [hasNewNumber, setHasNewNumber] = useState(false);
  const [hasOtherTimezone, setHasOtherTimezone] = useState(false);
  const [region, setRegion] = useState(localRegion);
  const [place, setPlace] = useState(localPlace);

  const timezonesPromise = useMemo(() => TimezoneApi.getZones(), []);

  const handleNumberToggle = (e) => {
    setHasNewNumber(e.target.checked);
  };

  const NewBusinessSchema = Yup.object().shape({
    name: Yup.string().trim().required('Please enter a name for your business'),
    phone: hasNewNumber
      ? Yup.string()
          .matches(regex.phone, 'Must be a valid phone number')
          .min(14, 'Must be a valid phone number')
          .required('Please enter a phone number')
      : Yup.string()
          .matches(regex.phone, 'Must be a valid phone number')
          .min(14, 'Must be a valid phone number'),
    timezone: Yup.string()
      .required('Please choose a timezone or use your local one')
      .trim()
      .matches(regex.timezone, 'Timezone must be valid'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
      timezone: localRegion + '/' + localPlace,
    },
    validationSchema: NewBusinessSchema,
    onSubmit: async (values) => {
      const userId = auth.userProfile?.id;
      const request: CreateOrganizationRequest = { ...values, userId };
      if (values.phone !== '') {
        request.phone = cleanPhoneNumber(values.phone);
      }

      try {
        await organizationApi.createOrganization(request);
        navigate(`/dashboard`);
        window.location.reload();
      } catch {
        console.log('Could not create');
      }
    },
  });

  const onRegionChange: React.ChangeEventHandler<HTMLSelectElement> = (
    event
  ) => {
    if (event.target.type === 'select-one') {
      setRegion(event.target.selectedOptions[0].value);
      setPlace('');
    }
  };

  const onPlaceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.target.type === 'select-one') {
      setPlace(event.target.selectedOptions[0].value);
      formik.setFieldValue('timezone', region + '/' + place);
    }
  };

  const handleTimeZoneToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasOtherTimezone(e.target.checked);
  };

  return (
    <Container {...styles.glassContainer}>
      <Heading>Create a Business</Heading>
      <form onSubmit={formik.handleSubmit}>
        <Stack {...styles.stack}>
          <FormControl
            isRequired
            isInvalid={!!formik.errors.name && formik.touched.name}
          >
            <FormLabel htmlFor="name">Business Name</FormLabel>
            <Input
              id="name"
              name="name"
              placeholder="Business name"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
            <FormErrorMessage {...styles.errorMessage}>
              {formik.errors.name}
            </FormErrorMessage>
          </FormControl>
          <Container justifyContent="flex-start" textAlign="left" p={0}>
            <Checkbox isChecked={hasNewNumber} onChange={handleNumberToggle}>
              Check to enter a different number for your business than your
              personal number
            </Checkbox>
          </Container>

          {/* <Container justifyContent="flex-start" textAlign="left" p={0}>
            <Checkbox
              isChecked={hasOtherTimezone}
              onChange={handleTimeZoneToggle}
            >
              Check to enter a different timezone for your business than the
              local timezone: <b>{`${region}/${place}`}</b>
            </Checkbox>
          </Container> */}
          {hasOtherTimezone && (
            <Async<TimezoneResponse> promise={timezonesPromise}>
              <Async.Pending>
                <Spinner />
              </Async.Pending>
              <Async.Fulfilled<TimezoneResponse>>
                {(timeZoneInfo) => (
                  <Container justifyContent="flex-start" textAlign="left" p={0}>
                    <FormControl
                      isRequired
                      isInvalid={!!formik.errors.timezone}
                    >
                      <FormLabel htmlFor="region">Timezone: Region</FormLabel>
                      <Select
                        id="region"
                        placeholder="Select region"
                        onChange={onRegionChange}
                        value={region}
                      >
                        {Object.keys(timeZoneInfo).map((region) => (
                          <option key={region} value={region}>
                            {region}
                          </option>
                        ))}
                      </Select>
                      <FormLabel htmlFor="region">Timezone: City</FormLabel>
                      <Select
                        id="place"
                        placeholder="Select place"
                        onChange={onPlaceChange}
                        value={place}
                      >
                        {(timeZoneInfo[region] ?? []).map((place) => (
                          <option key={place} value={place}>
                            {place}
                          </option>
                        ))}
                      </Select>
                      <FormErrorMessage {...styles.errorMessage}>
                        {formik.errors.timezone}
                      </FormErrorMessage>
                    </FormControl>
                  </Container>
                )}
              </Async.Fulfilled>
            </Async>
          )}
          {hasNewNumber && (
            <Input
              as={InputMask}
              id="phone"
              name="phone"
              type="tel"
              placeholder="Phone"
              maskPlaceholder=""
              mask="(999) 999 9999"
              value={formik.values.phone}
              onChange={formik.handleChange}
            />
          )}
          <Button type="submit" justifySelf="flex-end">
            Create
          </Button>
        </Stack>
      </form>
    </Container>
  );
}

const styles = {
  glassContainer: {
    backgroundColor: 'rgb(255, 255, 255, 0.2)',
    p: 20,
    align: 'center',
    rounded: 'lg',
    size: 'sm',
    minH: '550px',
  },
  stack: {
    align: 'center',
    maxW: '400px',
    mt: '20',
    spacing: 6,
  },
  header: {
    fontWeight: 'normal',
    align: 'center',
  },
  errorMessage: {
    color: 'pink',
    p: 1,
    fontWeight: 'bold',
  },
};

import React, { useContext, useEffect, useState } from 'react';
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { User } from '../../api/models/user';
import { userApi } from '../../api/users';
import { Auth } from 'aws-amplify';
import { Spinner } from '@chakra-ui/react';

export enum AuthStatus {
  SignedIn,
  SignedOut,
  InProgress,
}

export interface AuthState {
  userProfile: User | null;
  authStatus: AuthStatus;
  initiateChallenge: (phoneNumber: string) => void;
  answerChallenge: (code: string) => Promise<boolean>;
  isAuthenticated: () => boolean;
  hydrateProfile: () => void;
  signUserOut: () => void;
}

type AuthContextProps = {
  children?: React.ReactNode;
};

export const AuthContext = React.createContext<AuthState | null>(null);

export function useAuthContext(): AuthState {
  const ctx = useContext(AuthContext);
  if (ctx === null) {
    throw Error(
      'useAuthContext requires AuthProvider to be used higher in the component hierarchy'
    );
  }

  return ctx;
}

const PROFILE_STORAGE_KEY = 'userProfile';

export const AuthProvider: React.FunctionComponent<AuthContextProps> = ({
  children,
}: AuthContextProps) => {
  const [authStatus, setAuthStatus] = useState(AuthStatus.SignedOut);
  const [userProfile, setUserProfile] = useState<User | null>(null);
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null);
  const [session, setSession] = useState<CognitoUserSession | null>(null);

  const hydrateProfile = React.useCallback(async () => {
    if (authStatus !== AuthStatus.SignedIn || !session) {
      return;
    }

    const phone = session!.getIdToken().decodePayload().phone_number;

    try {
      const userResponse = await userApi.getUserByPhone(phone);
      setUserProfile(userResponse[0].user);
    } catch (e) {
      console.error('failed to hydrate profile', e);
    }
  }, [session, authStatus]);

  useEffect(() => {
    const onLoad = async () => {
      try {
        setAuthStatus(AuthStatus.InProgress);
        const newSession = await Auth.currentSession();
        const jsonProfile = localStorage.getItem(PROFILE_STORAGE_KEY);
        if (jsonProfile) {
          const storedUserProfile: User = JSON.parse(jsonProfile);
          setUserProfile(storedUserProfile);
        }
        setSession(newSession);
        setAuthStatus(AuthStatus.SignedIn);
      } catch (e) {
        console.error('No current session on load', e);
        setAuthStatus(AuthStatus.SignedOut);
      }
    };

    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (session) {
      hydrateProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  useEffect(() => {
    if (userProfile) {
      localStorage.setItem(PROFILE_STORAGE_KEY, JSON.stringify(userProfile));
    }
  }, [userProfile]);

  const isAuthenticated = React.useCallback((): boolean => {
    return authStatus === AuthStatus.SignedIn && userProfile !== null;
  }, [authStatus, userProfile]);

  async function initiateChallenge(phoneNumber: string) {
    setAuthStatus(AuthStatus.InProgress);
    const cognitoUser = await Auth.signIn(phoneNumber);
    setCognitoUser(cognitoUser);
  }

  const signUserOut = () => {
    setAuthStatus(AuthStatus.SignedOut);
    setUserProfile(null);
    setCognitoUser(null);
    setSession(null);
    // localStorage.removeItem('userProfile');
    localStorage.clear();
  };

  const answerChallenge = React.useCallback(
    async (code: string): Promise<boolean> => {
      if (!cognitoUser) {
        console.error('cognitoUser missing!');
        setAuthStatus(AuthStatus.SignedOut);
        return false;
      }

      const newCognitoUser = await Auth.sendCustomChallengeAnswer(
        cognitoUser,
        code
      );

      try {
        const newSession = await Auth.currentSession();
        setSession(newSession);
        setAuthStatus(AuthStatus.SignedIn);
        setCognitoUser(newCognitoUser);
        return true;
      } catch (e) {
        console.error('answering challenge failed: ', e);
        setAuthStatus(AuthStatus.SignedOut);
      }
      return false;
    },
    [cognitoUser]
  );

  const state: AuthState = {
    userProfile,
    authStatus,
    isAuthenticated,
    initiateChallenge,
    answerChallenge,
    hydrateProfile,
    signUserOut,
  };

  return (
    <AuthContext.Provider value={state}>
      {(authStatus === AuthStatus.SignedIn && userProfile !== null) ||
      authStatus !== AuthStatus.SignedIn ? (
        children
      ) : (
        <Spinner />
      )}
    </AuthContext.Provider>
  );
};

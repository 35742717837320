import { api } from 'api/api';

export type TimezoneResponse = Record<string, string[]>;

export interface ITimezoneApi {
  getZones(): Promise<TimezoneResponse>;
}

export const TimezoneApi = {
  getZones: async (): Promise<TimezoneResponse> => {
    const response = await api.get('/timezones');

    return response.data;
  },
};

// export const useTimezoneApi = (): ITimezoneApi => {
//     const getZones = async () => {
//         const response = await api.get('/timezones');

//         return response.data
//     }

//     return {getZones};
// }

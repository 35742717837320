import {
  Stack,
  Heading,
  Flex,
  ChakraComponent,
  IconProps,
} from '@chakra-ui/react';

interface props {
  title: string;
  Icon: ChakraComponent<'svg', IconProps>;
}

export default function Feature({ title, Icon }: props) {
  return (
    <Stack {...styles.stack}>
      <Heading as="h3" {...styles.title}>
        {title}
      </Heading>
      <Flex {...styles.container}>
        <Icon {...styles.icon} />
      </Flex>
    </Stack>
  );
}

const styles = {
  stack: {
    align: 'center',
    spacing: [2, 4],
  },
  title: {
    size: 'md',
  },
  container: {
    justify: 'center',
    align: 'center',
  },
  icon: {
    w: '50px',
    h: '50px',
  },
};

import { extendTheme } from '@chakra-ui/react';

// Component style overrides
import Button from './components/button';
import Container from './components/container';

// Global style overrides
import styles from './styles';

// Extends default Chakra UI Theme with overrides
const overrides = {
  styles,
  components: {
    Button,
    Container,
  },
};

export default extendTheme(overrides);

import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  GridItem,
  Heading,
  Spinner,
  Stack,
  StackDirection,
} from '@chakra-ui/react';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../context/AuthContext';
import { Organization } from '../../api/models/organization';
import { organizationApi } from '../../api/organizations';
import NewBusinessForm from '../NewBusinessForm';
import BusinessDashboard from '../BusinessDashboard';
import { AiOutlineCloseCircle, AiOutlineEdit } from 'react-icons/ai';
import DeleteBusinessModal from './DeleteBusinessModal';

interface Props {
  organizations?: Organization[];
}

// TODO: Separate these subcomponents into their own files
function AllBusinessDashboard({ organizations }: Props) {
  const navigate = useNavigate();
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const onOpenDelete = () => {
    setIsDeleteOpen(true);
  };

  const onCloseDelete = () => {
    setIsDeleteOpen(false);
  };

  return (
    <>
      <Heading textAlign="center">Your business below</Heading>
      {/* <Grid
        justifyContent={'center'}
        templateColumns={{
          base: 'repeat(2, 1fr)',
          sm: '1fr repeat(2, 1fr)',
          md: '1fr repeat(4, 1fr)',
          lg: '1fr repeat(5, 1fr)',
        }}
      > */}
      <Stack {...styles.businessStack}>
        {organizations?.map((organization) => {
          return (
            <GridItem key={organization.id} display={'flex'}>
              <Box
                key={`${organization.id}`}
                as="button"
                onClick={() =>
                  navigate(`/dashboard/${organization.id}&${organization.name}`)
                }
                {...styles.businessBox}
                _hover={{
                  background: 'rgba(200,200,200,0.3)',
                }}
              >
                {organization.name}
              </Box>
            </GridItem>
          );
        })}
        {/* Commented out as a WIP, will revisit later */}
        {/* <Box
          as="button"
          onClick={() => navigate('new')}
          {...styles.businessBox}
        >
          + Add a new business
        </Box> */}
        <Button
          justifyContent="center"
          alignItems="center"
          leftIcon={<AiOutlineCloseCircle />}
          colorScheme="red"
          onClick={onOpenDelete}
        >
          Delete Business
        </Button>
        <DeleteBusinessModal
          id={organizations![0].id}
          name={organizations![0].name}
          isOpen={isDeleteOpen}
          onClose={onCloseDelete}
        />
      </Stack>
    </>
  );
}

function WelcomeMessage() {
  const auth = useAuthContext();
  const navigate = useNavigate();

  return (
    <Stack align="center" spacing={8}>
      <Stack>
        <Heading as="h1" {...styles.header}>
          Welcome to <strong>s</strong>chext!
        </Heading>
        <Heading as="h1" {...styles.header}>
          Get started by creating your first business.
        </Heading>
      </Stack>
      <Button onClick={() => navigate('/dashboard/new')}>Create</Button>
    </Stack>
  );
}

export default function Dashboard() {
  const auth = useAuthContext();
  const [organizations, setOrganizations] = useState<any>();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      const result = await organizationApi.getOrganizations(
        auth.userProfile!.id
      );
      setOrganizations(result.organizations);
      setIsLoaded(true);
    })();
  }, [auth.userProfile?.id]);

  return (
    <Container {...styles.container}>
      <Routes>
        {isLoaded ? (
          <Route
            index
            element={
              organizations?.length > 0 ? (
                <AllBusinessDashboard organizations={organizations} />
              ) : (
                <WelcomeMessage />
              )
            }
          />
        ) : (
          <Route element={<Spinner size="xl" />} />
        )}

        <Route path="new" element={<NewBusinessForm />} />
        <Route path=":business/*" element={<BusinessDashboard />} />
      </Routes>
    </Container>
  );
}

const styles = {
  container: {
    minHeight: 'calc(100vh - 350px)',
    justify: 'center',
    size: 'lg',
    py: 20,
  },
  glassContainer: {
    backgroundColor: 'rgb(255, 255, 255, 0.2)',
    p: 20,
    align: 'center',
    rounded: 'lg',
    size: 'sm',
    minH: '550px',
  },
  stack: {
    align: 'center',
    maxW: '400px',
    mt: '20',
    spacing: 6,
  },
  header: {
    fontWeight: 'normal',
    align: 'center',
  },
  errorMessage: {
    color: 'pink',
    p: 1,
    fontWeight: 'bold',
  },
  businessStack: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  businessBox: {
    width: 200,
    height: 150,
    backgroundColor: 'rgb(255, 255, 255, 0.2)',
    // align: 'center',
    rounded: 'lg',
    size: 'sm',
    margin: 5,
    fontWeight: 'bold',
  },
};

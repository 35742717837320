const regex = {
  name: /^[a-z ,.'-]+$/i,
  password:
    /^(?=.{7,})(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[*.!@$%^&(){}[\]:;<>,?/~_+-=|\\]).*$/,
  phone:
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
  timezone: /^[a-zA-Z]*\/.*$/,
};

export default regex;

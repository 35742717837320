import { Flex, Button, Link, Heading } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

export default function Header() {
  return (
    <Flex as="header" {...styles.container}>
      <Heading as={RouterLink} {...styles.header} to="/">
        <span>s</span>chext
      </Heading>
      <Flex as="nav" {...styles.nav}>
        <Button {...styles.cta} as={RouterLink} to="/signup">
          Sign Up
        </Button>
        <Link as={RouterLink} to="/signin">
          Log In
        </Link>
      </Flex>
    </Flex>
  );
}

const styles = {
  header: {
    fontWeight: 'normal',
    size: 'lg',
    sx: {
      span: {
        fontWeight: 'bold',
      },
    },
  },
  container: {
    color: 'white',
    p: 6,
    justify: 'space-between',
    w: '100%',
  },
  nav: {
    align: 'center',
    gap: 10,
  },
  cta: {
    variant: 'outline',
    size: 'md',
    _hover: { color: 'black', bg: 'white' },
    fontWeight: 'normal',
  },
};
